




















































import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "BusinessPlanUndraw",
})
export default class BusinessPlanUndraw extends Vue {
}
