



































import Vue from "vue";

import Component from "vue-class-component";

@Component({
  name: "PhotoSessionUndraw",
})
export default class PhotoSessionUndraw extends Vue {
}
