<template>
  <div class="upcoming-events">
    <div class="upcoming-events__heading" v-html="$t('upcomingEvents/title')"></div>
    <div class="upcoming-events__content">
      
        <div v-if="!$root.isMobile && slides.length > 4" class="slider__nav">
          <Button
            class="slider__prev"
            variant="secondary"
            size="lg"
            iconLeft="arrow-left"
            shape="circle"
            @click="onPrev"
          />
          <Button
            class="slider__next"
            variant="secondary"
            size="lg"
            iconLeft="arrow-right"
            shape="circle"
            @click="onNext"
          />
        </div>

      <div class="slider">
        <div class="slider__container swiper-wrapper">
          <div
            v-for="(slide, index) in slides"
            :key="index"
            class="slider__item swiper-slide"
            :class="{ 'slider__item--last': index === slides.length-1 }"
          >
            <span
              v-for="(item, index2) in slide"
              :key="index2"
            >
              <span v-if="item.placeholder" class="event"></span>
              <img v-else :src="$root.isMobile ? item.mobile : item.desktop" class="event" alt="" />
            </span>
          </div>
        </div>

        <div class="slider__pagination"></div>
      </div>

    </div>
  </div>
</template>


<script lang="js">
import Swiper, { Pagination, Navigation } from 'swiper';

Swiper.use([Pagination, Navigation]);

export default {
  name: 'UpcomingEvents',
  props: {
    items: Array,
  },
  data() {
    return {
      slider: null,
      slides: [],
    };
  },
  methods: {
    onPrev() {
      if (this.slider) this.slider.slidePrev();
    },
    onNext() {
      if (this.slider) this.slider.slideNext();
    },
    createSlider() {
      this.slider = new Swiper('.slider', {
        pagination: {
          el: '.slider__pagination',
          type: 'bullets',
          clickable: true,
        },
        slidesPerView: 1,
        spaceBetween: this.$root.isMobile ? 32 : 46.67,
        speed: 500,
        simulateTouch: true,
        autoHeight: true,
        loop: true,
      });
    },
    setSlides() {
      const slidesPerView = this.$root.isMobile ? 2 : 4;
      const slides = [];
      
      for (let i = 0; i < this.$props.items.length; i += slidesPerView) {
        const slide = [];
        for (let k = 0; k < slidesPerView; k += 1) {
          if (i + k < this.$props.items.length) {
            slide.push(Object.assign({}, this.$props.items[i + k]));
          } else {
            slide.push({ placeholder: true });
          }
        }
        slides.push(slide);
      }

      this.slides = slides;
    },
  },
  mounted() {
    this.setSlides();
    if ((this.$root.isMobile && this.$props.items.length <= 2)
       || (!this.$root.isMobile && this.$props.items.length <= 4)) {
      return;
    }
    setTimeout(() => { this.createSlider(); }, 250);
  }
}
</script>


<style lang="scss" scoped>
  @import "./upcoming-events";
</style>