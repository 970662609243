<template>
  <div class="features">
    
    <div class="features__container">
      <Row :verticalGutters="true">
        <Col
          :size="{ default: '12', md: '6', lg: 'stretch' }"
          v-for="(feature, i) in items"
          :key="i"
        >
          <div
            class="feature__item"
            :class="{
              'feature__item--custom': isCustom,
              'item_clickable': feature.link !== '',
            }"
            @click.stop="onGoTo(feature.link)"
          >
            <base-illustration width="260" height="194" viewBox="0 0 261 194" class="feature__image">
              <component :is="feature.img" />
            </base-illustration>
            <div class="feature__title" v-html="feature.title"></div>
            <div class="feature__text" v-html="feature.text"></div>
          </div>
        </Col>
      </Row>
    </div>

  </div>
</template>


<script lang="js">
import BaseIllustration from '@/components/base-illustration/base-illustration.vue';
import PhotoSessionUndraw from '@/components/illustrations/photo-session-undraw.vue';
import TransferMoneyUndraw from '@/components/illustrations/transfer-money-undraw.vue';
import BusinessPlanUndraw from '@/components/illustrations/business-plan-undraw.vue';

export default {
  name: 'Features',
  components: {
    BaseIllustration,
    PhotoSessionUndraw,
    TransferMoneyUndraw,
    BusinessPlanUndraw,
  },
  props: {
    isCustom: Boolean,
  },
  data() {
    return {
      features: [
        {
          title: this.$t('features/organizerTitle'),
          text: this.$t('features/organizerText'),
          img: "photo-session-undraw",
          link: "/for-organizers",
        },
        {
          title: this.$t('features/photographerTitle'),
          text: this.$t('features/photographerText'),
          img: "transfer-money-undraw",
          link: "/for-photographers",
        },
        {
          title: this.$t('features/businessTitle'),
          text: this.$t('features/businessText'),
          img: "business-plan-undraw",
          link: "/for-business",
        },
      ],
      customFeatures: [
        {
          title: "We are fast",
          text: "We have fast and reliable tagging system. So we can deliver completely tagged photos to our your customers within 6 hours after the end of the event.",
          img: "photo-session-undraw",
          link: "",
        },
        {
          title: "We are smart",
          text: "Our hybrid AI recognition system can obtain up to 98% accuracy in face recognition. We combine face and BIB recognition technology to provide the maximum number of participant’s photos in a seconds",
          img: "transfer-money-undraw",
          link: "",
        },
        {
          title: "We are flexible",
          text: "We have several types of cooperation with race directors. So one can find the best solution thet suits best his type of event and number of participants",
          img: "business-plan-undraw",
          link: "",
        },
      ],
    };
  },
  computed: {
    items() {
      if (this.$props.isCustom) {
        return this.customFeatures;
      } else {
        return this.features;
      }
    },
  },
  methods: {
    onGoTo(link) {
      if (this.$props.isCustom) return;
      if (link) window.location.href = link;
    },
  },
};
</script>


<style lang="scss" scoped>
  @import "./features";
</style>
