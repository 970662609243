

































































































import Component from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import { Watch } from "vue-property-decorator";
import Moment from "moment";

import BaseComponent from "@/components/base-component.vue";
import SearchEvent from "@/components/home/search-event.vue";
import BaseIcon from "@/components/base-icon/base-icon.vue";
import IconSearch from "@/components/icons/icon-search.vue";
import IconSearch2 from "@/components/icons/icon-search2.vue";
import IconCheckBold from "@/components/icons/icon-check-bold.vue";
import { EventsState } from "@/store/modules/events";
import { SettingsState } from "@/store/modules/settings";

@Component({
  name: "SearchFormFull",
  props: {
    eventId: String,
    eventName: String,
    eventDate: String,
    key_: String,
    focused: Boolean,
    isBig: Boolean,
  },
  components: {
    SearchEvent,
    BaseIcon,
    IconSearch,
    IconSearch2,
    IconCheckBold,
  }
})
export default class SearchFormFull extends BaseComponent {
  
  private readonly eventsState: EventsState = getModule(EventsState, this.$store);
  private readonly settingsState: SettingsState = getModule(SettingsState, this.$store);

  eventId_: string = "";
  eventName_: string = "";
  eventDate_: string = "";
  isEventSelected: boolean = false;
  isTagged: boolean = false;

  eventsDialog: boolean = false;
  eventsDialogKey: string = "";
  eventsHeight: string = "auto";

  get theme(): any {
    return this.settingsState.theme;
  }

  get key(): string {
    return this.$props.key_;
  }

  get eventFullString(): string {
    if (!this.eventName_) return "";
    
    return (
      this.eventName_ +
      " (" +
      Moment.utc(this.eventDate_.slice(0, 10)).format("DD.MM.YYYY") +
      ")"
    );
  }

  async updateState(): Promise<void> {
    if (this.$props.eventId && this.$props.eventName) {
      this.eventId_ = this.$props.eventId;
      this.eventName_ = this.$props.eventName;
      this.eventDate_ = this.$props.eventDate;
      this.isEventSelected = true;
    } else {
      this.eventId_ = "";
      this.eventName_ = "";
      this.eventDate_ = "";
      this.isEventSelected = false;
    }
  }

  @Watch("eventsDialog")
  async onEventsDialog(): Promise<void> {
    if (this.eventsDialog) {
      this.eventsHeight = "56px";
    }
    this.$emit('shown', this.eventsDialog);
  }

  @Watch("key")
  async onKeyChanged(): Promise<void> {
    if (this.$props.focused) {
      setTimeout(async () => {
          await this.onEditEvent();
        }, 
        100
      );
    }
  }

  async onEditEvent(): Promise<void> {
    this.eventsDialog = true;
    this.eventsDialogKey = Math.random().toString();
  }

  async onShown(shown: boolean): Promise<void> {
    this.$emit('shown', shown);
  }

  async onEventChanged(hasResults: boolean): Promise<void> {
    if (hasResults) {
      setTimeout(async () => await this.updateEventsHeight(), 100);
    } else {
      this.eventsHeight = "56px";
    }
  }

  async updateEventsHeight(): Promise<void> {
    const e = document.getElementById('events');
    const f = document.getElementById('eventSearchForm');
    if (e == null || f == null) return;

    const t = e.getBoundingClientRect().top;
    const windowHeight = window.innerHeight;
    const height = windowHeight - t - 16;

    const formHeight = f.offsetHeight;

    this.eventsHeight = Math.min(height, formHeight) + "px";
  }

  async onEventSelected(value: {
    eventId: string;
    title: string;
    date: string;
    tagged: boolean;
  }): Promise<void> {
    this.eventsDialog = false;
    this.eventId_ = value.eventId || "";
    this.eventName_ = value.title;
    this.eventDate_ = value.date;
    this.isTagged = value.tagged;
    this.isEventSelected = true;
    this.$emit("eventSelected", value);
  }

  async onEventCanceled(): Promise<void> {
    this.eventId_ = "";
    this.eventName_ = "";
    this.eventDate_ = "";
    this.isEventSelected = false; 
    this.eventsDialog = false;
    let event = new Event("resize");
    window.dispatchEvent(event);
  }

  async created(): Promise<void> {
    await this.updateEventsHeight();

    window.addEventListener("resize", async () => {
        await this.updateEventsHeight();
      }
    );
  }

  async mounted(): Promise<void> {
    if (this.$props.eventId) {
      await this.eventsState.getEventById(this.$props.eventId);
      const event: any = this.eventsState.event;

      if (event === null) {
        this.isTagged = false;
      } else {
        if (event.searchingPhotos.byTag === true) {
          this.isTagged = true;
        } else {
          this.isTagged = false;
        }
      }
    }

    await this.updateState();

    await this.updateEventsHeight();
  }
}
