









































































































































import Component from "vue-class-component";
import { getModule } from "vuex-module-decorators";
import { Watch } from "vue-property-decorator";
import Moment from "moment";

import BaseComponent from "@/components/base-component.vue";
import Banner from "@/components/banner/banner.vue";
import Loader from "@/components/loader.vue";
import BaseIcon from "@/components/base-icon/base-icon.vue";
import IconDown from "@/components/icons/icon-down.vue";
import IconDown2 from "@/components/icons/icon-down2.vue";
import IconEnvironment from "@/components/icons/icon-environment.vue";
import IconEnvironment2 from "@/components/icons/icon-environment2.vue";
import IconHourglass from "@/components/icons/icon-hourglass.vue";
import IconAquathlon from "@/components/icons/disciplines/icon-aquathlon.vue";
import IconBasketballPlayer from "@/components/icons/disciplines/icon-basketball-player.vue";
import IconBeachVolleyball from "@/components/icons/disciplines/icon-beach-volleyball.vue";
import IconBiathlon from "@/components/icons/disciplines/icon-biathlon.vue";
import IconClimbing from "@/components/icons/disciplines/icon-climbing.vue";
import IconCompass from "@/components/icons/disciplines/icon-compass.vue";
import IconCrossCountrySkiing from "@/components/icons/disciplines/icon-cross-country-skiing.vue";
import IconCycling from "@/components/icons/disciplines/icon-cycling.vue";
import IconDogPaw from "@/components/icons/disciplines/icon-dog-paw.vue";
import IconDuathlon from "@/components/icons/disciplines/icon-duathlon.vue";
import IconGymnastics from "@/components/icons/disciplines/icon-gymnastics.vue";
import IconMountain from "@/components/icons/disciplines/icon-mountain.vue";
import IconRunning from "@/components/icons/disciplines/icon-running.vue";
import IconSkiing from "@/components/icons/disciplines/icon-skiing.vue";
import IconSnowboarding from "@/components/icons/disciplines/icon-snowboarding.vue";
import IconSoccerPlayer from "@/components/icons/disciplines/icon-soccer-player.vue";
import IconSteps from "@/components/icons/disciplines/icon-steps.vue";
import IconSwimming from "@/components/icons/disciplines/icon-swimming.vue";
import IconTableTennis from "@/components/icons/disciplines/icon-table-tennis.vue";
import IconTennisPlayer from "@/components/icons/disciplines/icon-tennis-player.vue";
import IconTrackAndField from "@/components/icons/disciplines/icon-track-and-field.vue";
import IconTriathlon from "@/components/icons/disciplines/icon-triathlon.vue";
import IconWalking from "@/components/icons/disciplines/icon-walking.vue";
import IconWindsurfing from "@/components/icons/disciplines/icon-windsurfing.vue";
import { EventsState } from "@/store/modules/events";
import { SettingsState } from "@/store/modules/settings";

@Component({
  name: "RecentEvents",
  components: {
    Banner,
    Loader,
    BaseIcon,
    IconDown,
    IconDown2,
    IconEnvironment,
    IconEnvironment2,
    IconHourglass,
    IconAquathlon,
    IconBasketballPlayer,
    IconBeachVolleyball, 
    IconBiathlon, 
    IconClimbing, 
    IconCompass, 
    IconCrossCountrySkiing, 
    IconCycling, 
    IconDogPaw, 
    IconDuathlon, 
    IconGymnastics, 
    IconMountain, 
    IconRunning, 
    IconSkiing, 
    IconSnowboarding, 
    IconSoccerPlayer, 
    IconSteps, 
    IconSwimming, 
    IconTableTennis, 
    IconTennisPlayer, 
    IconTrackAndField, 
    IconTriathlon, 
    IconWalking, 
    IconWindsurfing, 
  },
})
export default class RecentEvents extends BaseComponent {

  private readonly eventsState: EventsState = getModule(EventsState, this.$store);
  private readonly settingsState: SettingsState = getModule(SettingsState, this.$store);

  events_: any[] = [];
  updating: boolean = false;
  startFrom: number = 0;

  bannerMoved: boolean = false;

  icons: any[] = [
    { file: "aquathlon.svg", icon: "icon-aquathlon" },
    { file: "basketball-player.svg", icon: "icon-basketball-player" },
    { file: "beach-volleyball.svg", icon: "icon-beach-volleyball" },
    { file: "biathlon.svg", icon: "icon-biathlon" },
    { file: "climbing.svg", icon: "icon-climbing" },
    { file: "compass.svg", icon: "icon-compass" },
    { file: "cross-country-skiing.svg", icon: "icon-cross-country-skiing" },
    { file: "cycling.svg", icon: "icon-cycling" },
    { file: "dog-paw.svg", icon: "icon-dog-paw" },
    { file: "duathlon.svg", icon: "icon-duathlon" },
    { file: "gymnastics.svg", icon: "icon-gymnastics" },
    { file: "mountain.svg", icon: "icon-mountain" },
    { file: "running.svg", icon: "icon-running" },
    { file: "skiing.svg", icon: "icon-skiing" },
    { file: "snowboarding.svg", icon: "icon-snowboarding" },
    { file: "soccer-player.svg", icon: "icon-soccer-player" },
    { file: "steps.svg", icon: "icon-steps" },
    { file: "swimming.svg", icon: "icon-swimming" },
    { file: "table-tennis.svg", icon: "icon-table-tennis" },
    { file: "tennis-player.svg", icon: "icon-tennis-player" },
    { file: "track-and-field.svg", icon: "icon-track-and-field" },
    { file: "triathlon.svg", icon: "icon-triathlon" },
    { file: "walking.svg", icon: "icon-walking" },
    { file: "windsurfing.svg", icon: "icon-windsurfing" },
  ];

  get events(): any[] {
    return this.events_;
  }

  get eventsTotal(): number {
    return this.eventsState.eventsTotal;
  }

  get theme(): any {
    return this.settingsState.theme;
  }

  get themeDone(): boolean {
    return this.settingsState.themeDone;
  }

  get lang(): string {
    return this.settingsState.lang;
  }

  get showBanners(): boolean {
    const host: string = window.location.origin;
    if (host == "http://localhost:8080" || 
        host == "http://192.168.0.101:8080" ||
        0 == host.indexOf("https://medianew.") ||
        0 == host.indexOf("https://medianew-test.") ||
        0 == host.indexOf("https://mysportusa.")) {
      const devShowRealBanners = this.$route.query.devShowRealBanners;
      if (devShowRealBanners !== undefined) {
        return devShowRealBanners === "true";
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  get isMySportUSA(): boolean {
    return this.settingsState.isMySportUSA === true && this.lang === 'en';
  }

  formatDate(date: string): string {
    return Moment.utc(date).format("D MMMM");
  }

  getIcon(logo: any): string {
    if (!logo || !logo.logoId) {
      return "-";
    }

    const name: string = logo.logoId.toLowerCase();
    const icon: any[] = this.icons.filter((i: any) => i.file == name);

    if (icon.length > 0) {
      return icon[0].icon || "-";
    } else {
      return "-";
    }

  }

  getItemClasses(): string[] {
    const classes: string[] = [];
    classes.push(...['v-expansion-panel', 'list__item']);
    if (!this.smOnly && !this.mdOnly) classes.push('item--large');
    return classes;
  }

  isProcessing(event: any): boolean {
    if (!event) return false;
    return event.searchingPhotos.byTag === false && event.searchingPhotos.byFace === false;
  }

  moveBanner(): void {
    const banner = document.getElementById('recentEventsBanner');
    const event = document.getElementById('recentEvent_1');
    if (banner === null || event === null) return;

    event.insertAdjacentElement('afterend', banner);
    setTimeout(() => { 
      banner.classList.remove('banner-content--hidden');
      this.bannerMoved = true;
    }, 100);
  }

  @Watch("themeDone")
  async onThemeDone(): Promise<void> {
    if (this.$route.name != 'home') return;
    if (!this.themeDone) return;
    
    await this.init();
  }

  @Watch("lang")
  async onLangChanged(): Promise<void> {
    await this.settingsState.setLoadingEvents(true);
    
    this.loading = true;
    this.updating = true;
    this.events_ = [];
    this.startFrom = 0;
    
    await this.fillEvents();

    this.updating = false;
    this.loading = false;
    
    await this.settingsState.setLoadingEvents(false);
  }

  async onEventClick(event: any): Promise<void> {
    if (this.isProcessing(event)) return;
    
    this.settingsState.reachGoal('media_event_selected');
    this.settingsState.reachGoal('event_selected_from_calendar');

    await this.$router.push({
      name: "event",
      params: {
        id: event.externalEventId,
    }
    });
  }

  async onViewAllPhotos(event: any): Promise<void> {
    await this.$router.push({
      name: "event-photos",
      params: {
        id: event.externalEventId,
      }
    });
  }

  async onBannerLoad(): Promise<void> {
    const banner = document.getElementById('recentEventsBanner');
    if (banner === null) return;
    if (banner.offsetHeight === 1) {
      banner.style.display = 'none';
    }
  }

  async fillEvents(): Promise<void> {
    await this.settingsState.setLoadingEvents(true);

    const payload: any = {
      search: "",
      pagination: { offset: 0, count: 12 },
    };
    const organizerId = this.settingsState.organizerId;
    if (organizerId) {
      payload["organizerId"] = organizerId;
    }

    await this.eventsState.getEvents(payload);

    const events: any[] = [];
    const dates: string[] = [];
    const available: any[] = this.eventsState.events;

    for (let i = 0; i < available.length; i++) {
      const item: any = available[i];

      item['tagged'] = available[i].searchingPhotos.byTag === true;

      const date: string = item.date.slice(0, 10);

      if (dates.length == 0) {
        dates.push(date);
        events.push({ date: date, items: [item] });
        continue;
      }

      const index: number = dates.indexOf(date);
      if (index >= 0) {
        events[index].items.push(item);
      } else {
        dates.push(date);
        events.push({ date: date, items: [item] });
      }
    }

    let eventIndex: number = 0;
    for (let i = 0; i < events.length; i++) {
      for (let j = 0; j < events[i].items.length; j++) {
        events[i].items[j]['eventIndex'] = eventIndex;
        eventIndex++;
      }
    }

    this.events_ = events;
    this.startFrom = eventIndex;
    
    await this.settingsState.setLoadingEvents(false);

    setTimeout(() => { this.moveBanner() }, 100);
  }

  async updateEvents(): Promise<void> {
    this.updating = true;
    
    const payload: any = {
      search: "",
      pagination: { offset: this.startFrom, count: 12 },
    };
    const organizerId = this.settingsState.organizerId;
    if (organizerId) {
      payload["organizerId"] = organizerId;
    }
    
    await this.eventsState.getEvents(payload);

    const events: any[] = this.events_;
    const dates: string[] = this.getDates(events);
    const available: any[] = this.eventsState.events;

    if (available.length == 0) {
      this.updating = false;
      return;
    }

    for (let i = 0; i < available.length; i++) {
      const item: any = available[i];
      item['tagged'] = available[i].searchingPhotos.byTag === true;
      const date: string = item.date.slice(0, 10);

      if (dates.length == 0) {
        dates.push(date);
        events.push({ date: date, items: [item] });
        continue;
      }

      const index: number = dates.indexOf(date);
      if (index >= 0) {
        events[index].items.push(item);
      } else {
        dates.push(date);
        events.push({ date: date, items: [item] });
      }
    }

    let eventIndex: number = 0;
    for (let i = 0; i < events.length; i++) {
      for (let j = 0; j < events[i].items.length; j++) {
        events[i].items[j]['eventIndex'] = eventIndex;
        eventIndex++;
      }
    }

    this.startFrom = eventIndex;

    setTimeout(() => { this.moveBanner() }, 100);
    
    this.updating = false;
  }

  getDates(events: any[]): string[] {
    const dates: string[] = [];
    for (let i = 0; i < events.length; i++) {
      dates.push(events[i].date);
    }
    return dates;
  }

  async init(): Promise<void> {
    await this.fillEvents();

    this.updating = false;

    this.loading = false;
  }

  async mounted(): Promise<void> {
    this.loading = true;
    
    this.updating = true;

    if (this.themeDone) {
      await this.init();
    }
  }

}
